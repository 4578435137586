import React from "react";
import { Carousel } from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel CSS
// import { CiCircleFilled } from "@ant-design/icons";
import { BsCircleFill } from "react-icons/bs";

import benefitImg from "../../assets/loyalty/benefits.webp";

const BenefitForCus = () => {
  return (
    <div className=" flex flex-col justify-center items-center">
      <h3 className="loyalty-card-title text-white text-xl">
        Benefits for Customers
      </h3>
      <ul className="max-w-80 ">
        <li className="flex flex-row justify-start gap-5 align-middle ">
          <p>
            <BsCircleFill size={5} className="mt-3" />
          </p>
          <p>Rewards and Incentives: (Earn Points, Exclusive Offers)</p>
        </li>
        <li className="flex flex-row justify-start gap-5 align-middle">
          <p>
            <BsCircleFill size={5} className="mt-3" />
          </p>
          <p>Enhanced Shopping Experience: (Personalization, Convenience)</p>
        </li>
        <li className="flex flex-row justify-start gap-5 align-middle">
          <p>
            <BsCircleFill size={5} className="mt-3" />
          </p>
          <p>
            Feeling Valued and Recognized: (Membership Tiers, Exclusive Events)
          </p>
        </li>
        <li className="flex flex-row justify-start gap-5 align-middle">
          <p>
            <BsCircleFill size={5} className="mt-3" />
          </p>
          <p>Savings and Benefits: (Discounts, Free Products)</p>
        </li>
        <li className="flex flex-row justify-start gap-5 align-middle">
          <p>
            <BsCircleFill size={5} className="mt-3" />
          </p>
          <p>Ease of Use: (Mobile Access, No need for physical cards)</p>
        </li>
        <li className="flex flex-row justify-start gap-5 align-middle">
          <p>
            <BsCircleFill size={5} className="mt-3" />
          </p>
          <p>
            Engagement and Enjoyment: (Gamification, Community Participation)
          </p>
        </li>
      </ul>
    </div>
  );
};

const BenefitForBusiness = () => {
  return (
    <div className=" flex flex-col mb-10   justify-center items-center">
      <h3 className="loyalty-card-title text-white text-xl">
        Benefits for Businesses
      </h3>
      <ul className="feature-card-info text-white">
        <li className="flex flex-row justify-start gap-5 align-middle">
          <p>
            <BsCircleFill size={5} className="mt-3" />
          </p>
          <p>Increased Customer Retention</p>
        </li>
        <li className="flex flex-row justify-start gap-5 align-middle">
          <p>
            <BsCircleFill size={5} className="mt-3" />
          </p>
          Enhanced Customer Experience
        </li>
        <li className="flex flex-row justify-start gap-5 align-middle">
          <p>
            <BsCircleFill size={5} className="mt-3" />
          </p>
          <p>Valuable Customer Insights</p>
        </li>
        <li className="flex flex-row justify-start gap-5 align-middle">
          <p>
            <BsCircleFill size={5} className="mt-3" />
          </p>
          <p>Increased Sales and Revenue</p>
        </li>
        <li className="flex flex-row justify-start gap-5 align-middle">
          <p>
            <BsCircleFill size={5} className="mt-3" />
          </p>
          <p>Brand Loyalty and Advocacy</p>
        </li>
        <li className="flex flex-row justify-start gap-5 align-middle">
          <p>
            <BsCircleFill size={5} className="mt-3" />
          </p>
          <p>Cost-Effective Marketing</p>
        </li>
      </ul>
    </div>
  );
};

const Benefits = () => {
  return (
    <div className="loyalty-benefit-container pt-64 mb-5 border-b-8 border-b-blue-500 flex flex-col justify-center items-center md:flex-row  gap-10 text-white">
      <img
        src={benefitImg}
        alt="benefits"
        className="w-full max-w-[560px]  h-auto"
      />
      <Carousel
        showThumbs={true}
        showStatus={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={3000}
        transitionTime={5}
        style={{ maxWidth: "600px", paddingBottom:"30px" }}>
        <div className="text-white">
          <BenefitForCus />
        </div>
        <div className="text-white">
          <BenefitForBusiness />
        </div>
      </Carousel>
    </div>
  );
};

export default Benefits;
