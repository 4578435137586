import React from "react";
import SectionHeader from "../cards/Header";
import mobile from "../../assets/E-Commerce Icons/Mobile.webp";
import laptop from "../../assets/E-Commerce Icons/Laptop.webp";
import desktop from "../../assets/E-Commerce Icons/Desktop.webp";
import { motion } from "framer-motion";

const ECommerceHeader = () => {
  return (
    <div>
      <SectionHeader
        title="OBS E-Commerce"
        info="E-commerce refers to the buying and selling of goods or services over the internet. It involves the transaction of money, data, and services between buyers and sellers through E-commerce, a wide range of activities, including online retail , B2B, B2C , payment and supply chain management. Business-to-Business (B2B) e-commerce refers to the online exchange of goods, services, or information between businesses. Business-to-Consumer (B2C) e-commerce, where businesses sell products or services directly to consumers, B2B e-commerce involves transactions between businesses, such as manufacturers, wholesalers, distributors, and retailers."
      />
      <div className="image-container flex flex-row justify-center items-end min-h-80">
        <motion.img
          initial={{ opacity: 0, x: 0 }}
          whileInView={{ opacity: 1, x: window.innerWidth < 480 ? 205 : 255 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          viewport={{ once: false, amount: 0.5 }}
          src={mobile}
          alt="mobile-img"
          className="image-1  object-contain w-auto h-72 z-40"
        />
        <motion.img
          initial={{ opacity: 0, scale: 0 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, ease: "easeInOut" }}
          viewport={{ once: false, amount: 0.5 }}
          src={desktop}
          alt="desktop-img"
          className="image-2 object-contain w-auto h-96"
        />
        <motion.img
          initial={{ opacity: 0, x: 0 }}
          whileInView={{ opacity: 1, x: -175 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          viewport={{ once: false, amount: 0.5 }}
          src={laptop}
          alt="tablet-img"
          className="w-auto object-contain h-60 z-20 mt-20"
        />
      </div>
    </div>
  );
};

export default ECommerceHeader;
