import React from "react";
import "./index.css";
import integration from "../../assets/distributed 1.webp";
import data from "../../assets/data 1.webp";
import login from "../../assets/login 1.webp";
import subscription from "../../assets/subscription 1.webp";
import adaptation from "../../assets/adaptation 1.webp";
import optimization from "../../assets/optimization 1.webp";
import PaymentBenefitCard from "../cards/PaymentBenefitCard";

const Benefits = () => {
  return (
    <div className="payment-benefits-container ">
      <PaymentBenefitCard
        imgSrc={integration}
        title="Easy Integration"
        desc="Developer-friendly APIs for quick and easy integration with websites and mobile applications."
      />

      <PaymentBenefitCard
        imgSrc={data}
        title="Real-Time Analytics and Reporting"
        desc="Comprehensive dashboard for monitoring transactions and financial performance."
      />

      <PaymentBenefitCard
        imgSrc={login}
        title="User-Friendly Interface"
        desc="Responsive customer support to assist with any issues or queries."
      />

      <PaymentBenefitCard
        imgSrc={subscription}
        title="Recurring Billing and Subscriptions"
        desc="Automated billing for subscription-based businesses."
      />

      <PaymentBenefitCard
        imgSrc={adaptation}
        title="Global Reach with Local Adaptation"
        desc="Partnerships with regional banks and financial institutions to enhance transaction efficiency."
      />

      <PaymentBenefitCard
        imgSrc={optimization}
        title="Mobile Optimization"
        desc="Mobile-friendly checkout experience and merchants to manage transactions."
      />
    </div>
  );
};

export default Benefits;
