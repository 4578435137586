import React from "react";
import "./index.css";
import { ArrowRightOutlined } from "@ant-design/icons";
import Hotel_Management from "../../assets/16-9-24/image 3.webp";
import { Link } from "react-router-dom";

const ProductSlide3 = () => {
  return (
    <div className="product-slide">
      <div className="product-info">
        <p className="headline">Our Products</p>
        <h3>OBS Tenant-Property Management System</h3>
        <p className="detail-info">
          Origin Property-Tenant Management System is a property management
          software solution for business sectors which is initially created to
          small, mid and large enterprise real estate and shopping mall business
          developed by Origin Business Solutions Co.Ltd. OPTMS is a specialized
          software solution designed to streamline and automate various aspects
          of property management, particularly in managing tenant-related tasks
          such as Residential Property management, Condo management, Real Estate
          Management, Shopping Mall Management and Tenant Management, etc.
        </p>
        <Link to="/tenant">
          <p className="read-more headline" type="text">
            Read More <ArrowRightOutlined />
          </p>
        </Link>
      </div>
      <img
        className="clinic-img carousel-img"
        src={Hotel_Management}
        alt="clinic-management"
        style={{ scale: "1" }}
      />
    </div>
  );
};

export default ProductSlide3;
