// import JobPostCard from "../cards/JobPostCard";
import "./index.css";
export default function JobPosts() {
  // const jobs = [
  //   { position: "Project Coordinator", isUrgent: true },
  //   { position: "Frontend Developer", isUrgent: false },
  //   { position: "Backend Developer", isUrgent: false },
  //   { position: "Sales and Marketing Manager", isUrgent: true },
  //   { position: "Product Owner", isUrgent: false },
  //   { position: "UI/UX Designer", isUrgent: false },
  // ];

  return (
    // <div className="flex flex-wrap justify-evenly">
    //   {jobs.map((job, index) => (
    //     <JobPostCard
    //       key={index}
    //       position={job.position}
    //       urgent={job.isUrgent}
    //     />
    //   ))}
    // </div>
    <div className="justify-center flex-col w-full items-center  flex my-10">
      {/* <img src={noJobPost} alt="No Jobs Available For Now" /> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        width="100"
        height="100"
        fill="none">
        {/* Background rectangle representing the card */}
        <rect x="10" y="20" width="70" height="50" rx="5" fill="#000" />

        {/* "JOB" text on the card */}
        <text
          x="25"
          y="40"
          fontFamily="Arial, sans-serif"
          fontSize="12"
          fill="#fff"
          fontWeight="bold">
          JOB
        </text>

        {/* Lines representing text on the card */}
        <rect x="25" y="45" width="40" height="3" fill="#fff" rx="1.5" />
        <rect x="25" y="52" width="30" height="3" fill="#fff" rx="1.5" />

        {/* Magnifying glass */}
        <circle
          cx="70"
          cy="60"
          r="8"
          stroke="#fff"
          strokeWidth="2"
          fill="#000"
        />
        <line
          x1="74"
          y1="64"
          x2="85"
          y2="75"
          stroke="#000"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
      <p>There is no job post yet.</p>
    </div>
  );
}
