import React from "react";
import "./index.css";
import EcommerceCard from "../cards/EcommerceCard";
import ecomMini from "../../assets/E-Commerce Icons/Mobile.webp";
import ecomB2C from "../../assets/E-Commerce Icons/Desktop.webp";
import ecomB2B from "../../assets/E-Commerce Icons/Laptop.webp";

const Cards = () => {
  return (
    <div className="card-container">
      <EcommerceCard
        imgSrc={ecomB2C}
        title="E-Commerce (Mini)"
        desc="As with most businesses, an e-commerce business needs to differentiate itself from the competition. This presents a daunting challenge to most online enterprises; especially those just entering the e-commerce arena such as greengrocers and mini-markets who are just starting to realize the full potential of e-commerce ...."
      />

      <EcommerceCard
        imgSrc={ecomB2B}
        title="E-Commerce (B2C)"
        desc="Business-to-business (B2B) apps connect businesses with other businesses, partners, and stakeholders. Some B2B apps are available for public download, while others are intended only for internal use cases. You can also create B2B ecommerce apps designed specifically for wholesale buyers. Complete business transactions ...."
      />

      <EcommerceCard
        imgSrc={ecomMini}
        title="E-Commerce (B2B)"
        desc="This presents a daunting challenge to most online enterprises; especially those just entering the e-commerce arena such as greengrocers and mini-markets who are just starting to realize the full potential of e-commerce presence for their shop. Smart businesses realize that at least part of the differentiation will come from the quality ...."
      />
    </div>
  );
};

export default Cards;
