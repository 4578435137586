import React from "react";
import "./index.css";
import bootstrap from "../../assets/languages/bootstrap.webp";
import html from "../../assets/languages/html.webp";
import js from "../../assets/languages/js.webp";
import mongodb from "../../assets/languages/mongodb.webp";
import nodejs from "../../assets/languages/nodejs.webp";
import php from "../../assets/languages/php.webp";
import react from "../../assets/languages/react.webp";
import vue from "../../assets/languages/vue.webp";
import css from "../../assets/languages/css.webp";

const LanguagesWeUse = () => {
  const images = [vue, bootstrap, react, mongodb, html, css, js, nodejs, php];

  return (
    <>
      <div className="languages-we-use">
        <h3 className="font-extrabold  mb-4">Languages We Use</h3>
        <p>
          Thousands of programming languages—often classified as imperative,
          functional, logic, or object-oriented—have been developed for a wide
          variety of uses.
        </p>
      </div>
      <div className="icon-container">
        <div className="languages-icons ">
          {images.map((img, index) => (
            <img
              key={index}
              src={img}
              alt="programminga language"
              className="language-icon"
            />
          ))}
          {images.map((img, index) => (
            <img
              key={index}
              src={img}
              alt="programminga language"
              className="language-icon"
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default LanguagesWeUse;
