import React from "react";
import "../webDevelopment/index.css";
import lightingHeader from "../../assets/lighting_header.webp";

const SectionHeader = ({ title, info }) => {
  const sentences = info
    ? info.split(". ").filter((sentence) => sentence.trim() !== "")
    : []; // Fallback to an empty array if info is undefined or not provided

  return (
    <div
      className="header"
      style={{
        alignItems: "center",
        justifyContent: "start",
      }}>
      <img src={lightingHeader} alt="lighting Header" className="opacity-0" />
      <h3 className="-translate-y-8">{title}</h3>
      {sentences.map((sentence, index) => (
        <p key={index}>{sentence}.</p>
      ))}
    </div>
  );
};

export default SectionHeader;
