import React from "react";
import "./index.css";
import "../webDevelopment/index.css";
import { motion } from "framer-motion";
// import { } from "../../assets/E-Commerce Icons/Photo.webp";
import { SiteMapCard } from "../cards/SiteMapCard";
import {
  siteMapImage,
  caslessPay,
  checklist,
  cyberSecurity,
  procurement,
  productResearch,
  products,
  report,
  responsive,
  review,
  shipping,
  shoppingCart,
  shoppingOnline,
  stockKeepingUnit,
  trustworthiness,
  wishlist,
} from "../../assets/E-Commerce Icons";
const Sitemap = () => {
  const cards = [
    {
      title: "Product Catalog",
      icon: products,
      className: "gradient",
    },
    {
      title: "Product Search and Filtering",
      icon: productResearch,
      className: "white",
    },

    {
      title: "Shopping Card",
      icon: shoppingCart,
      className: "gradient",
    },
    {
      title: "Checkout Process",
      icon: checklist,
      className: "white",
    },

    {
      title: "Inventory Management",
      icon: procurement,
      className: "gradient",
    },
    {
      title: "Order Management",
      icon: stockKeepingUnit,
      className: "white",
    },

    {
      title: "Shipping and Delivery Options",
      icon: shipping,
      className: "gradient",
    },
    {
      title: "Payment Gateway Integration",
      icon: caslessPay,
      className: "white",
    },

    {
      title: "Customer Accounts",
      icon: trustworthiness,
      className: "gradient",
    },
    {
      title: "Review and Ratings",
      icon: review,
      className: "white",
    },

    {
      title: "Wishlist/Save for Later",
      icon: wishlist,
      className: "gradient",
    },
    {
      title: "Responsive Design",
      icon: responsive,
      className: "white",
    },

    {
      title: "Promotion and Discount",
      icon: shoppingOnline,
      className: "gradient",
    },
    {
      title: "Analytics and Reporting",
      icon: report,
      className: "white",
    },

    {
      title: "Security Features",
      icon: cyberSecurity,
      className: "gradient",
    },
  ];
  return (
    <>
      <div className=" justify-center hidden md:flex">
        <motion.img
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1, ease: "easeInOut" }}
          exit={{ opacity: 0 }}
          viewport={{ once: false, amount: 0.5 }}
          src={siteMapImage}
          alt="sitemap"
          className="w-4/5 h-full"
        />
      </div>
      <div className="flex flex-row justify-center items-center flex-wrap md:hidden ">
        {cards.map((card, index) => {
          return (
            <div
              key={index}
              className="sm:w-1/3 flex justify-center items-center">
              <SiteMapCard
                title={card.title}
                className={card.className}
                img={card.icon}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Sitemap;
