import React from "react";
import processOne from "../../assets/process-1.webp";
import processTwo from "../../assets/process-2.webp";
import "../webDevelopment/index.css";
import "./index.css";
import { motion } from "framer-motion";
import { BsCircleFill } from "react-icons/bs";

const ProcessStep = ({ stepNumber, title, content, customClass = "" }) => {
  return (
    <motion.div
      initial={{ opacity: 0, x: stepNumber % 2 === 0 ? 100 : -100 }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 1.5, ease: "easeInOut" }}
      viewport={{ once: false, amount: 0.5 }}
      exit={{ opacity: 0, x: stepNumber % 2 === 0 ? 100 : -100 }}
      className="max-w-96 ">
      <div className={`facts ${customClass}`}>
        <div className="text-white number bg-gradient-to-br from-sky-300 to-blue-950 p-1 rounded-3xl gradient-number process-number prev">
          {stepNumber}
        </div>
        <div className="process-fact">
          <h3>{title}</h3>
          {content}
        </div>
      </div>
    </motion.div>
  );
};

const Process = () => {
  return (
    <>
      <div className="flex md:flex-row flex-col items-center justify-center">
        <img src={processOne} width="auto" height="350px" alt="process-one" />
        <div className="md:w-1/2 overflow-x-visible">
          <ProcessStep
            stepNumber="01"
            title="Client Consultation and Requirement Analysis"
            content={
              <p className="flex flex-row">
                <BsCircleFill
                  size={10}
                  style={{ marginTop: "5px", marginRight: "15px" }}
                />
                Engage with clients to understand their specific business
                requirements, challenges, and objectives.
              </p>
            }
          />
          <ProcessStep
            stepNumber="02"
            title="Design and Prototyping"
            customClass="md:ml-52"
            content={
              <>
                <p className="flex flex-row">
                  <BsCircleFill
                    size={10}
                    style={{ marginTop: "5px", marginRight: "15px" }}
                  />
                  Create detailed design specifications, including system
                  architecture, user interface (UI), and user experience (UX)
                  design.
                </p>
                <p className="flex flex-row">
                  <BsCircleFill
                    size={10}
                    style={{ marginTop: "5px", marginRight: "15px" }}
                  />
                  Develop prototypes or mockups to visualize the solution and
                  gather client feedback for iterative refinement.
                </p>
              </>
            }
          />
          <ProcessStep
            stepNumber="03"
            title="Development"
            content={
              <ul>
                <li className="flex flex-row">
                  <BsCircleFill
                    size={10}
                    style={{ marginTop: "5px", marginRight: "15px" }}
                  />
                  Utilize suitable programming languages, frameworks, and tools
                  to build the software.
                </li>
                <li className="flex flex-row">
                  <BsCircleFill
                    size={10}
                    style={{ marginTop: "5px", marginRight: "15px" }}
                  />
                  Follow agile methodologies to ensure iterative development and
                  continuous client involvement.
                </li>
                <li className="flex flex-row">
                  <BsCircleFill
                    size={8}
                    style={{ marginTop: "5px", marginRight: "15px" }}
                  />
                  Implement functionalities and features as per the approved
                  design.
                </li>
              </ul>
            }
          />
        </div>
      </div>
      <div className="flex md:flex-row flex-col-reverse justify-center items-center">
        <div className="md:w-1/2">
          <ProcessStep
            stepNumber="04"
            title="Testing and Quality Assurance"
            customClass="md:translate-x-24"
            content={
              <ul>
                <li className="flex flex-row">
                  <BsCircleFill
                    size={10}
                    style={{ marginTop: "5px", marginRight: "15px" }}
                  />
                  Conduct comprehensive testing, including unit tests,
                  integration tests, system tests, and user acceptance testing
                  (UAT).
                </li>
                <li className="flex flex-row">
                  <BsCircleFill
                    size={10}
                    style={{ marginTop: "5px", marginRight: "15px" }}
                  />
                  Identify and resolve any bugs or issues to ensure the software
                  meets quality standards and client expectations.
                </li>
              </ul>
            }
          />
          <ProcessStep
            stepNumber="05"
            title="Deployment and Integration"
            content={
              <ul>
                <li className="flex flex-row">
                  <BsCircleFill
                    size={5}
                    style={{ marginTop: "5px", marginRight: "15px" }}
                  />
                  Deploy the software in the client's operational environment.
                </li>
                <li className="flex flex-row">
                  <BsCircleFill
                    size={7}
                    style={{ marginTop: "5px", marginRight: "15px" }}
                  />
                  Ensure seamless integration with existing systems and smooth
                  data migration.
                </li>
              </ul>
            }
          />
          <ProcessStep
            stepNumber="06"
            title="Maintenance and Support"
            customClass="md:translate-x-24"
            content={
              <ul>
                <li className="flex flex-row">
                  <BsCircleFill
                    size={10}
                    style={{ marginTop: "5px", marginRight: "15px" }}
                  />
                  Provide technical support and regular updates and enhancements
                  to keep the software up-to-date
                </li>
                <li className="flex flex-row">
                  <BsCircleFill
                    size={10}
                    style={{ marginTop: "5px", marginRight: "15px" }}
                  />
                  Monitor software performance and to enhance the software's
                  performance and user satisfaction
                </li>
              </ul>
            }
          />
        </div>
        <img src={processTwo} width="auto" height="350px" alt="process-two" />
      </div>
    </>
  );
};

export default Process;
