import React from "react";
import "./BasicPlan.css";
import { motion } from "framer-motion";

const PremiumPlan = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -100 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: false, amount: 0.5 }}
      transition={{ duration: 1, ease: "easeInOut" }}
      exit={{ opacity: 0, y: 200 }}
      className=" overflow-y-hidden max-w-60 max-h-[480px] standard-plan-card">
      <div className="flex flex-col bg-white m-[2px] px-5 min-h-[475px] rounded-xl">
        <div className="max-h-8  flex items-center justify-center">
          <button className="relative  bg-gradient-to-r from-sky-600 to-blue-950 text-white font-semibold py-3 px-10 text-xs clip-path-button">
            Premium Plan
          </button>
        </div>
        <div className="plan-features">
          <h4 className="text-xs text-gray-500 font-normal">Starts From </h4>
          <p className="font-bold text-center text-black text-xl mb-">
            2,000,000 MMK
          </p>
          <ul className="">
            <li className="px-2 my-[20px] text-black text-sm">Customized</li>
            <li className="px-2 my-[20px] text-black text-sm">
              Dedicated Hosting
            </li>
            <li className="px-2 my-[20px] text-black text-sm">20 GB</li>
            <li className="px-2 my-[20px] text-black text-sm">Unlimited</li>
            <li className="px-2 my-[20px] text-black text-sm">Included</li>
            <li className="px-2 my-[20px] text-black text-sm">Yes</li>
            <li className="px-2 my-[20px] text-black text-sm">Yes</li>
            <li className="px-2 my-[20px] text-black text-sm">500,000 MMK</li>
            <li className="px-2 my-[20px] text-black text-sm">50,000 MMK</li>
          </ul>
        </div>
      </div>
    </motion.div>
  );
};

export default PremiumPlan;
