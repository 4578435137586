import React from "react";
import "./BasicPlan.css";
import { motion } from "framer-motion";

const BasicPlan = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: false, amount: 0.5 }}
      transition={{ duration: 1, ease: "easeInOut" }}
      exit={{ opacity: 0, y: 200 }}
      className=" overflow-y-hidden max-w-60 max-h-[480px] basic-plan-card">
      <div className="max-h-8  flex items-center justify-center">
        <button className="relative bg-gradient-to-r from-sky-600 to-blue-950 text-white font-semibold py-3 px-10 text-xs clip-path-button">
          Basic Plan
        </button>
      </div>
      <div className="plan-features">
        <h4 className="text-xs">Starts From </h4>
        <p className="font-bold text-center text-xl mb-">800,000 MMK</p>
        <ul className="">
          <li className="px-2 my-[20px] text-sm">Up to 10 Pages</li>
          <li className="px-2 my-[20px] text-sm">Shared Hosting</li>
          <li className="px-2 my-[20px] text-sm">2 GB</li>
          <li className="px-2 my-[20px] text-sm">2 TB Transfer</li>
          <li className="px-2 my-[20px] text-sm">Included</li>
          <li className="px-2 my-[20px] text-sm">N/A</li>
          <li className="px-2 my-[20px] text-sm">N/A</li>
          <li className="px-2 my-[20px] text-sm">300,000 MMK</li>
          <li className="px-2 my-[20px] text-sm">50,000 MMK</li>
        </ul>
      </div>
    </motion.div>
  );
};

export default BasicPlan;
