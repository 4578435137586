import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import paymentImg from "../../assets/16-9-24/Payment Solution.webp";
import "./index.css";
import { useNavigate } from "react-router-dom";
// import { Button } from "antd";

const PaymentSolution = () => {
  const navigate = useNavigate();
  return (
    <div className="payment-solution xl:pl-28  xl:pr-28">
      <div>
        <div className="payment-solution-image-container  flex justify-center items-center z-50">
          <img
            src={paymentImg}
            alt="payment-img"
            className="h-[375px]  min-w-[375px] payment-solution-image z-50"
            style={{ marginLeft: "20px" }}
          />
        </div>
      </div>
      <div className="payment-solution-info">
        <h3>OBS's Payment Solution</h3>
        <p>Connecting OBS's Payment Solution for simplifying transactions</p>
        <span className="read-more cursor-pointer" type="text" onClick={()=>navigate("/payment")}>
          Read More <ArrowRightOutlined />
        </span>
      </div>
    </div>
  );
};

export default PaymentSolution;
