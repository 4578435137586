import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./index.css";
import { motion } from "framer-motion";
import BasicPlan from "./BasicPlan";
import StandardPlan from "./StandardPlan";
import PremiumPlan from "./PremiumPlan";

const WebsitePackagePlan = () => {
  return (
    <>
      <div className="plan-detail text-center overflow-y-hidden mobile-x-axis-cancel pl-52 pr-52">
        <h3 className="text-nowrap">Website Package Plans</h3>
        <p className="text-sm md:text-xl">
          Find the perfect plan for your needs with our straightforward pricing
          options. Discover competitive rates and unlock the exceptional value
          we offer for our top-notch services.
        </p>
      </div>
      <div className="package-plan justify-center overflow-y-hidden lg:flex flex-col gap-5 lg:flex-row">
        <motion.div
          initial={{ opacity: 0, x: -200 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: false, amount: 0.5 }}
          transition={{ duration: 1, ease: "easeInOut" }}
          exit={{ opacity: 0, x: -200 }}
          className="plan-detail max-w-80  md:w-1/3">
          <h3 className="text-left md:text-center mt-5">Package Plan</h3>
          <p className="text-left md:text-center">
            Development Fees (One Time Charges)
          </p>
          <p className="text-left md:text-center">Website Pages</p>
          <p className="text-left md:text-center">Web Hosting</p>
          <p className="text-left md:text-center">SSD Storage</p>
          <p className="text-left md:text-center">Bandwidth</p>
          <p className="text-left md:text-center">Free SSL</p>
          <p className="text-left md:text-center">Website Optimization</p>
          <p className="text-left md:text-center">
            Google Analytics / Social Media Integration
          </p>
          <p className="text-left md:text-center">
            Maintenance & Support Plan (Annually)
          </p>
          <p className="text-left md:text-center">Domain Fees (Annually)</p>
        </motion.div>
        <div className="hidden md:flex flex-col h-full overflow-y-hiddden md:flex-row justify-start">
          <BasicPlan />
          <StandardPlan />
          <PremiumPlan />
        </div>
      </div>
    </>
  );
};

export default WebsitePackagePlan;
