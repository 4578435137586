import React, { useState } from "react";
import { Layout, Menu, Drawer, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import navbarLogo from "../assets/navbarLogo.webp";
import { Link, useNavigate } from "react-router-dom";
import "./index.css";

const { Header } = Layout;

const App = () => {
  const navigate = useNavigate();
  const items = [
    {
      key: "services",
      label: "Services",
      children: [
        { label: "Web Development", key: "webDevelopment" },
        { label: "Application Development", key: "applicationDevelopment" },
        { label: "Software Development", key: "softwareDevelopment" },
        { label: "Software Outsourcing", key: "softwareOutsourcing" },
        { label: "Cloud Hosting & Managed Service", key: "cloudHosting" },
      ],
    },
    {
      key: "products",
      label: "Products",
      children: [
        { key: "ecommerce", label: "OBS E-commerce" },
        { key: "tenant", label: "OBS Tenant-Property Management System" },
        { key: "loyalty", label: "OBS Loyalty & Membership System" },
        { key: "payment", label: "OBS Payment Solutions" },
      ],
    },
    { key: "solutions", label: "Solutions" },
    { key: "aboutUs", label: "About Us" },
    { key: "career", label: "Career" },
    {
      key: "contactUs",
      label: <span className="contact-us-label">Contact Us</span>,
    },
  ];

  const [current, setCurrent] = useState("");
  const [visible, setVisible] = useState(false);

  const onClick = (e) => {
    setVisible(false);
    setCurrent(e.key);
    switch (e.key) {
      case "webDevelopment":
        navigate("/web-development");
        break;
      case "applicationDevelopment":
        navigate("/application-development");
        break;
      case "softwareDevelopment":
        navigate("/software-development");
        break;
      case "softwareOutsourcing":
        navigate("/software-outsourcing");
        break;
      case "cloudHosting":
        navigate("/cloud-hosting");
        break;
      case "ecommerce":
        navigate("/ecommerce");
        break;
      case "tenant":
        navigate("/tenant");
        break;
      case "loyalty":
        navigate("/loyalty");
        break;
      case "payment":
        navigate("/payment");
        break;
      case "solutions":
        navigate("/solutions");
        break;
      case "aboutUs":
        navigate("/about-us");
        break;
      case "career":
        navigate("/career");
        break;
      case "contactUs":
        navigate("/contact-us");
        break;
      default:
        break;
    }
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  return (
    <Layout>
      <nav>
        <Header
          style={{
            backgroundColor: "white",
            marginRight: "0px",
            paddingRight: "40px",
            borderTop: "8px solid #012D67",
            padding: "25px",
            paddingTop: "50px",
            paddingBottom: "30px",
          }}>
          <Link to="/">
            <img src={navbarLogo} alt="navbarLogo" />
          </Link>
          <Menu
            className="menu-horizontal"
            onClick={onClick}
            mode="horizontal"
            items={items}
          />
          <Button
            className="drawer-button border-0"
            icon={<MenuOutlined />}
            onClick={showDrawer}
          />
          <Drawer
            placement="right"
            onClose={closeDrawer}
            open={visible}
            style={{ marginLeft: "100px" }}>
            <Menu
              onClick={onClick}
              selectedKeys={[current]}
              mode="inline"
              items={items}
              style={{ maxWidth: "100%", fontSize: "10px" }}
            />
          </Drawer>
        </Header>
      </nav>
    </Layout>
  );
};

export default App;
