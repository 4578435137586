import React from "react";
import "../payment/index.css";

const PaymentBenefitCard = ({ imgSrc, title, desc }) => {
  return (
    <div className="payment-card-container min-w-80">
      <img src={imgSrc} alt={imgSrc} />
      <p className="payment-card-header">{title}</p>
      <p className="payment-card-desc">{desc}</p>
    </div>
  );
};

export default PaymentBenefitCard;
