import React from "react";
import { motion } from "framer-motion";
import "./index.css";
import FeatureCard from "../cards/FeatureCard";
import SixYearExp from "../../assets/features/6 years exp.webp";
import first from "../../assets/HeroIcons/First.webp";
import second from "../../assets/HeroIcons/Second.webp";
import third from "../../assets/HeroIcons/Third.webp";
import featureBg from "../../assets/loyalty/Ellipse24.webp";
import { useNavigate } from "react-router-dom";

const Feature = () => {
  const navigate = useNavigate();
  const featureCards = [
    {
      title: "Innovation and Agility",
      info: "OBS can quickly adapt to market changes and technology advancements. It can innovate by creating cutting-edge solutions ( advanced technology, cloud computing, data analytic ) to stay ahead of competitors and address emerging client needs.",
    },
    {
      title: "Customer-Centric Approach",
      info: "Focusing on customer service-oriented talent aligns with the company’s strategic focus on long-term client relationships and customer satisfaction.",
    },
    {
      title: "Talent Acquisition",
      info: "Recruiting talent with expertise allows the company to maintain the performance with technical skills developers by  strong problem-solving and critical-thinking capabilities, as they can quickly identify and address issues, enhancing product quality",
    },
  ];

  return (
    <>
      <div
        className="bg-contain pt-20 bg-top feature-header-container bg-no-repeat  "
        style={{
          backgroundImage: `url(${featureBg})`,
          backgroundSize: "30%",
        }}>
        <h1
          className="feature-title text-white font-bold text-xl"
          style={{ textAlign: "center" }}>
          Features
        </h1>
        <h3 style={{ textAlign: "center" }}>Our Strategic Values</h3>

        {/* <img src={featureImage} alt="feature-header" className="min-h-20" /> */}
        <p className="feature-info text-justify md:text-center p-3">
          Our strategic value focus in its ability to Leverage advanced
          technology , Innovation and Agility, Customizable and Scalable
          Solutions, Customer Centric Approach, Talent acquisition is also a
          critical component as attracting and retaining top talent drives to
          deliver competitive advantages, solve client problems, and foster
          business growth.
        </p>
      </div>

      <div className="justify-center flex-wrap -translate-y-10 gap-8 hidden md:flex overflow-y-hidden  h-92">
        <motion.div
          className=""
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, ease: "easeInOut" }}>
          <FeatureCard
            number={first}
            title={featureCards[0].title}
            info={featureCards[0].info}
          />
        </motion.div>
        <motion.div
          className=" -translate-y-6"
          initial={{ opacity: 0, x: -100, y: -5 }}
          whileInView={{ opacity: 1, x: -5 }}
          transition={{ duration: 0.8, ease: "easeInOut" }}>
          <FeatureCard
            number={second}
            title={featureCards[1].title}
            info={featureCards[1].info}
          />
        </motion.div>
        <motion.div
          className=""
          initial={{ opacity: 0, x: +100 }}
          whileInView={{ opacity: 1, x: 0, y: 12 }}
          transition={{ duration: 0.8, ease: "easeInOut" }}>
          <FeatureCard
            number={third}
            title={featureCards[2].title}
            info={featureCards[2].info}
          />
        </motion.div>
      </div>

      <div className="six-year-exp-container overflow-y-hidden">
        <motion.img
          initial={{ opacity: 0, y: 100, scale: 0.5 }}
          whileInView={{ opacity: 1, y: 0, scale: 1 }}
          transition={{ duration: 0.8, ease: "easeInOut" }}
          exit={{ opacity: 0, y: 100, scale: 0.5 }}
          viewport={{ once: false, amount: 0.5 }}
          className="w-auto h-fit max-h-52  cursor-pointer"
          src={SixYearExp}
          alt="six-year-exp"
        />
        <div className="justify-start flex flex-col md:w-1/3 overflow-y-hidden">
          <h3
            className="text-2xl font-bold"
            style={{ fontSize: "22px", fontWeight: 800 }}>
            OVER 6 YEARS EXPERIENCE
          </h3>{" "}
          <div className="h-[2px] mb-5 w-32 bg-black"></div>
          <p className="text-lg">
            Origin Business Solutions Company - OBS was established in 2018 and
            providing Digital Solutions providers in Myanmar.
          </p>
          <button
            className="explore-more-btn shadow-md"
            onClick={() => navigate("/contact-us")}>
            Contact Us
          </button>
        </div>
      </div>
    </>
  );
};

export default Feature;
