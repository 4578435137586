import React from "react";
const FeatureCard = ({ title, info, number }) => {
  return (
    <div className="h-full overflow-y-hidden overflow-x-hidden ">
      <img
        src={number}
        alt="number-image"
        className="translate-y-8 ml-5 mb-2 w-20 "
      />
      <div className="bg-gradient-to-r from-sky-300 to-blue-950 overflow-x-hidden rounded-2xl">
        <div className="flex tenant-card flex-col p-5 max-w-96 h-40 w-full bg-white overflow-x-hidden">
          <p className="feature-card-title text-left overflow-x-hidden">
            {title}
          </p>
          <p className="feature-card-info overflow-x-hidden">{info}</p>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard;
