import React from "react";
import { motion } from "framer-motion";

const PaymentTopCard = ({ heading, title, description, isleft }) => {
  return (
    <motion.div
      initial={{ opacity: 0, x: isleft ? -50 : 50 }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 1.5, ease: "easeInOut" }}
      viewport={{ once: false, amount: 0.8 }}
      className="gradient-bg p-[2px] rounded-xl">
      <div className="w-full rounded-xl h-full bg-white">
        <div className="flex pt-0 flex-col  sm:min-w-[450px]  max-w-[450px] min-h-64 p-5 max-h-64  rounded-lg justify-between">
          <p className="rounded-tl-xl -translate-x-6  flex items-center justify-center  max-h-8 max-w-28 font-semibold text-center text-white py-3 gradient-bg rounded-br-xl">
            {heading}
          </p>
          <h2
            className="text-center payment-header max-w-80 self-center place-self-center overflow-y-visible md:my-3"
            style={{ fontSize: "16px", lineHeight: "20px" }}>
            {title}
          </h2>
          {description.map((sentence, index) => (
            <p key={index} className="text-justify overflow-y-hidden  text-sm">
              {sentence}
            </p>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default PaymentTopCard;
