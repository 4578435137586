import React from "react";
import "./index.css";
import { Carousel } from "antd";
import ProductSlide1 from "./ProductSlide1";
import ProductSlide2 from "./ProductSlide2";
import ProductSlide3 from "./ProductSlide3";
import ProductSlide4 from "./ProductSlide4";

const OurProducts = () => {
  return (
    <div className="product-slides">
      <Carousel arrows arrowSize="20" dots={true}>
        <div>
          <ProductSlide1 />
        </div>
        <div>
          <ProductSlide2 />
        </div>
        <div>
          <ProductSlide3 />
        </div>
        <div>
          <ProductSlide4 />
        </div>
      </Carousel>
    </div>
  );
};

export default OurProducts;
