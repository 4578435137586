import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import { FaBriefcase, FaUserTie } from "react-icons/fa";
import { MdOutlineSupportAgent } from "react-icons/md";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    company: "",
    phoneNumber: "",
    emailAddress: "",
    service: "",
    products: "",
    subject: "",
    message: "",
    termsAccepted: false,
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  const inputFields = [
    {
      label: "Full Name",
      name: "fullName",
      type: "text",
      placeholder: "Full name",
    },
    {
      label: "Company / Organization",
      name: "company",
      type: "text",
      placeholder: "Company / Organization",
    },
    {
      label: "Phone Number",
      name: "phoneNumber",
      type: "text",
      placeholder: "Phone Number",
    },
    {
      label: "Email Address",
      name: "emailAddress",
      type: "email",
      placeholder: "Email address",
    },
  ];

  const serviceOptions = [
    "Web Development ",
    "Application Development ",
    "Software Development ",
    "Software Outsourcing ",
    "Cloud Hosting & Managed Service",
  ];
  const productOptions = [
    "OBS E-commerce",
    "OBS Tenant-Property Management System",
    "OBS Loyalty & Membership System",
    "OBS Payment Solutions",
  ];

  return (
    <div className="contact-us ">
      <h3 className="contact-form-header m-5 min-w-[65vw] text-center">
        Get In Touch
      </h3>
      <p className="desc -translate-y-5 min-w-[65vw]">
        We strive to respond to all inquiries as quickly as possible. Your
        questions and feedback are important to us, and we'll do our best to
        address them in a timely manner.
      </p>
      <div className="flex justify-between gap-5 w-full px-5 items-start">
        <div className="w-full"></div>

        <form
          onSubmit={handleSubmit}
          className="flex flex-col w-full min-w-[65vw]">
          <div className="md:grid md:grid-cols-2 w-full gap-5">
            {inputFields.map((field) => (
              <div key={field.name} className="form-design w-full">
                <label style={{ color: "white" }}>{field.label}</label>
                <input
                  type={field.type}
                  name={field.name}
                  placeholder={field.placeholder}
                  value={formData[field.name]}
                  onChange={handleInputChange}
                  style={{ color: "black" }}
                  className="w-full h-12 default:border-none default:outline-none focus:border-none focus:outline-none"
                />
              </div>
            ))}
            <div className="form-design w-full">
              <label style={{ color: "white" }}>Service</label>
              <select
                name="service"
                value={formData.service}
                onChange={handleInputChange}
                className="w-full h-12 default:border-none default:outline-none focus:border-none focus:outline-none"
                style={{ color: "black" }}>
                <option
                  value=""
                  disabled
                  selected
                  className="placeholder-option">
                  Select Service
                </option>
                {serviceOptions.map((service) => (
                  <option key={service} value={service}>
                    {service}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-design w-full">
              <label style={{ color: "white" }}>Products</label>
              <select
                name="products"
                value={formData.products}
                onChange={handleInputChange}
                className="w-full h-12 default:border-none default:outline-none focus:border-none focus:outline-none"
                style={{ color: "black" }}>
                <option value="" disabled className="placeholder-option">
                  Select Product
                </option>
                {productOptions.map((product) => (
                  <option
                    key={product}
                    value={product}
                    className="hover:bg-blue-950">
                    {product}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-design w-full">
            <label style={{ color: "white" }}>Subject</label>
            <input
              type="text"
              name="subject"
              placeholder="Subject"
              value={formData["subject"]}
              onChange={handleInputChange}
              style={{ color: "black" }}
              className="w-full h-12 default:border-none default:outline-none focus:border-none focus:outline-none"
            />
          </div>
          <div className="form-design w-full">
            <label style={{ color: "white" }}>Message</label>
            <textarea
              name="message"
              placeholder="Write a message"
              className="w-full p-3 default:border-none default:outline-none focus:border-none focus:outline-none"
              value={formData.message}
              onChange={handleInputChange}
              style={{ minWidth: 300, height: 200, color: "black" }}
            />
          </div>
          <div
            className="flex justify-center items-center md:justify-between flex-col md:flex-row w-full"
            style={{ justifyContent: "space-between" }}>
            <label className="flex flex-row justify-start items-center mb-2">
              <input
                type="checkbox"
                name="termsAccepted"
                checked={formData.termsAccepted}
                onChange={handleInputChange}
              />{" "}
              <span className="text-xs" style={{ color: "white" }}>
                I agree with terms of usage and privacy policy.
              </span>
            </label>
            <button
              className="rounded-md bg-gradient-to-r max-w-[250px] from-sky-400 to-blue-950 p-2 pl-10 pr-10"
              type="submit">
              Submit
            </button>
          </div>
        </form>
        <div className="flex flex-col w-full bg-white min-w-32 py-5 rounded-l-xl  min-h-32 items-center justify-evenly gap-2">
          <FaBriefcase className="m-1 text-black" size={30} />
          <p className="text-xl text-black font-semibold">Sale and Business</p>
          <p className="text-sm text-black">sales@obs.com.mm</p>
          <span className="h-[1px] w-32 bg-black"></span>
          <FaUserTie className="m-1 text-black" size={30} />
          <p className="text-xl text-black font-semibold">Customer Support </p>
          <p className="text-sm text-black">partner@obs.com.mm</p>
          <span className="h-[1px] w-32 bg-black"></span>
          <MdOutlineSupportAgent className="m-1 text-black" size={30} />
          <p className="text-xl text-black font-semibold">Customer Support </p>
          <p className="text-sm text-black">support@obs.com.mm</p>
        </div>
      </div>
      <div className="where-to-find bg-sky min-w-[60vw]">
        <p className="where-to-find-fact text-wrap">Our Office</p>
        <h3 className="where-to-find-header text-wrap">Here To Find</h3>
        <p className="where-to-find-desc text-wrap">
          Experience our expansive network of offices across the world,
          connecting you to our exceptional services wherever you are.
        </p>
        <Link to="https://maps.app.goo.gl/x6Qw5kxUtuAfxuvo7?g_st=com.google.maps.preview.copy">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3819.4261398934614!2d96.1430345!3d16.805202699999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30c1eb005d420f6f%3A0x5b03ecb041a76f6f!2sOrigin%20Business%20Solutions!5e0!3m2!1sen!2smm!4v1736751180479!5m2!1sen!2smm"
            height="450px"
            style={{ border: 0 }}
            title="Origin Business Solutions"
            allowFullScreen=""
            loading="lazy"
            className="w-full shadow-md rounded-xl"
            referrerPolicy="no-referrer-when-downgrade"></iframe>
        </Link>
      </div>
    </div>
  );
};

export default ContactForm;
