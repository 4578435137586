import React from "react";
import Application from "../../assets/application.webp";
import "../webDevelopment/index.css";
import { motion } from "framer-motion";

const Journey = () => {
  return (
    <div className="our-services mobile-x-axis-cancel">
      <div className="website-development flex-col md:flex-row  mobile-x-axis-cancel ">
        {/* Mobile Image */}
        <motion.img
          initial={{ opacity: 0, scale: 0.2 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          viewport={{ once: false, amount: 0.6 }}
          src={Application}
          className="md:hidden mt-20"
          alt="app-development-journey"
        />

        {/* First Group of Steps */}
        <div className="first-group  mobile-x-axis-cancel">
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            viewport={{ once: false, amount: 0.6 }}
            className="fact gradient md:flex-row flex-row-reverse">
            <p className="text w-full text-center ">Requirement Analysis</p>
            <div className="number">01</div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            viewport={{ once: false, amount: 0.6 }}
            className="fact white md:flex-row flex-row-reverse mobile-x-axis-cancel">
            <p className="text-center w-full">UI/UX Design</p>
            <div className="number gradient-number">02</div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            viewport={{ once: false, amount: 0.6 }}
            className="fact gradient md:flex-row flex-row-reverse">
            <p className="text-center w-full">Development</p>
            <div className="number">03</div>
          </motion.div>
        </div>

        {/* Desktop Image */}
        <motion.img
          initial={{ opacity: 0, scale: 0.2 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          viewport={{ once: false, amount: 0.6 }}
          src={Application}
          className="hidden md:block"
          alt="app-development-journey"
        />

        {/* Second Group of Steps */}
        <div className="first-group">
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            viewport={{ once: false, amount: 0.6 }}
            className="fact gradient">
            <div className="number">04</div>
            <p className="text-center w-full">Testing</p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            viewport={{ once: false, amount: 0.6 }}
            className="fact white">
            <div className="number gradient-number">05</div>
            <p className="text-center w-full">Deployment</p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            viewport={{ once: false, amount: 0.6 }}
            className="fact gradient">
            <div className="number">06</div>
            <p className="text-center w-full">Maintenance and Updates</p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Journey;
