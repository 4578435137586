import React from "react";
import { Layout } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import footerLogo from "../assets/footerLogo.webp";
import { Link } from "react-router-dom";
import { CiLocationOn, CiMail } from "react-icons/ci";
import { HiOutlinePhone } from "react-icons/hi2";
import "../index.css"; // Make sure to include the styles if necessary

const SocialMediaLink = ({ url, icon }) => (
  <Link to={url}>
    <FontAwesomeIcon
      style={{ width: 20, height: 20, marginRight: "10px" }}
      icon={icon}
      className="p-3 rounded-3xl border-2 border-white"
    />
  </Link>
);
const FooterLink = ({ text, to }) => (
  <div className="mt-3 group w-2/3 cursor-pointer hover:duration-1000 hover:border-b-2  hover:border-b-white hover:pb-2">
    <Link to={to} className="group-hover:duration-1000 ">
      {text}
    </Link>
  </div>
);

const ContactDetail = ({ icon, text }) => (
  <div className="flex mt-5 gap-2 flex-row align-center">
    <div className=" mr-3 p-2">{icon}</div>
    <div className="ml-4">{text}</div>
  </div>
);
const Footer = () => {
  return (
    <Layout className="footer-layout  ">
      <div className="footer-group  flex justify-evenly items-start flex-col md:flex-row pb-20">
        <div className="logo-social-media-group m-3">
          <img src={footerLogo} alt="footerLogo" />
          <div className="social-media-group">
            <SocialMediaLink
              url="https://www.facebook.com/originbusinesssolutions?mibextid=ZbWKwL"
              icon={faFacebook}
            />
            <SocialMediaLink
              url="https://www.linkedin.com/company/originbusinesssolutions/"
              icon={faLinkedin}
            />
            <FontAwesomeIcon
              style={{ width: 20, height: 20, marginRight: "10px" }}
              icon={faYoutube}
              className="p-3 rounded-3xl border-2 border-white"
            />
          </div>
        </div>

        <div className="services m-3 mt-5">
          <h4 className="text-xl">SERVICES</h4>
          <FooterLink text="Web Development" to="/web-development" />
          <FooterLink
            text="Application Development"
            to="application-development"
          />
          <FooterLink text="Software Development" to="software-development" />
          <FooterLink text="Software Outsourcing" to="software-outscoring" />
          <FooterLink
            text="Cloud Hosting & Managed Services"
            to="cloud-hosting"
          />
        </div>

        <div className="products m-3 mt-5">
          <h4 className="text-xl">PRODUCTS</h4>
          <FooterLink text="OBS E-Commerce" to="ecommerce" />
          <FooterLink
            text="OBS Tenant-Property Management System"
            to="tenant"
          />
          <FooterLink text="OBS Loyalty & Membership System" to="loyalty" />
          <FooterLink text="OBS Payment Solutions" to="solutions" />
        </div>

        <div className="solutions min-w-[224px] m-3 w-full mt-5">
          <h4 className="text-xl">SOLUTIONS</h4>
          <FooterLink text="Use Cases" to="#" />
          <FooterLink text="Industries" to="#" />
        </div>

        <div className="contact m-3 md:ml-5 mt-5">
          <h4 className="text-xl">CONTACT</h4>
          <ContactDetail
            icon={<HiOutlinePhone size={20} />}
            text="(+95) 97 5237 7778, (+95) 97 5237 7779"
          />
          <ContactDetail icon={<CiMail size={20} />} text="hr@obs.com.mm" />
          <ContactDetail
            icon={<CiLocationOn size={20} className="" />}
            text="No(5-D), 99 Condo, Dhammazedi Road, Kamaryut Township, Yangon"
          />
        </div>
      </div>
      <div
        style={{
          textAlign: "center",
          alignItems: "center",

          padding: "10px",
          height: "auto",
          background:
            "linear-gradient(102.28deg, #00BCE8 -12.02%, #012D67 77.71%)",
          color: "white",
        }}>
        <div className="md:text-xl text-xs  pb-5">
          © 2024 Origin Business Solution. All rights reserved.
        </div>
      </div>
    </Layout>
  );
};

export default Footer;
