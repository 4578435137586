import React from "react";
import "./index.css";
import { ArrowRightOutlined } from "@ant-design/icons";
import Hotel_Management from "../../assets/16-9-24/image 4.webp";
import { Link } from "react-router-dom";

const ProductSlide4 = () => {
  return (
    <div className="product-slide">
      <div className="product-info">
        <p className="headline">Our Products</p>
        <h3>Origin Loyalty and Membership System</h3>
        <p className="detail-info">
          A loyalty and membership application software is a digital solution
          that enables businesses to design, implement, and manage customer
          loyalty programs and membership systems. OBS’s Loyalty and Membership
          system provides tools for engaging customers, tracking their
          activities, rewarding their loyalty, and analyzing customer behavior
          to refine marketing strategies. By implementing a comprehensive
          loyalty and membership application software, businesses can
          effectively manage their loyalty programs, drive customer engagement,
          and achieve long-term growth..
        </p>
        <Link to="/loyalty">
          <p className="read-more headline" type="text">
            Read More <ArrowRightOutlined />
          </p>
        </Link>
      </div>
      <img
        className="clinic-img"
        src={Hotel_Management}
        alt="clinic-management"
        style={{ scale: "1" }}
      />
    </div>
  );
};

export default ProductSlide4;
