import React from "react";
import { Footer, Header, Content } from ".";

const MainLayout = () => {
  return (
    <div>
      <Header />
      <Content />
      <div className="border-t-8 border-t-blue-400 w-full rounded-t-3xl">
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
