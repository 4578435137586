import React from "react";
import ProcessOne from "../../assets/cloudHosting1.webp";
import ProcessTwo from "../../assets/CloudHosting2.webp";
import "../webDevelopment/index.css";
import "./index.css";
import { motion } from "framer-motion";

const Process = () => {
  const fadeInEffect = {
    initial: { opacity: 0, x: -50 },
    whileInView: { opacity: 1, x: 0 },
    transition: { duration: 1.5, delay: 0.5, ease: "easeInOut" },
    viewport: { once: false, amount: 0.5 },
    exit: { opacity: 0 },
  };

  return (
    <>
      <div className="flex flex-col md:flex-row justify-center items-center">
        <motion.img
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1.5, delay: 1, ease: "easeInOut" }}
          exit={{ opacity: 0 }}
          viewport={{ once: false, amount: 0.5 }}
          src={ProcessOne}
          width={300}
          height="auto"
          alt="process-one"
        />
        <div className="cloud-hosting-info">
          <motion.div {...fadeInEffect} className="cloud-hosting-fact one">
            <div className="loyalty-dev-num">01</div>
            <p>Cloud Consulting Service</p>
          </motion.div>
          <motion.div {...fadeInEffect} className="cloud-hosting-fact two">
            <div className="loyalty-dev-num">02</div>
            <p>Cloud Infrastructure Management</p>
          </motion.div>
          <motion.div {...fadeInEffect} className="cloud-hosting-fact three">
            <div className="loyalty-dev-num">03</div>
            <p>Cloud Migration Service</p>
          </motion.div>
          <motion.div {...fadeInEffect} className="cloud-hosting-fact four">
            <div className="loyalty-dev-num">04</div>
            <p>Cost Monitoring and Reporting</p>
          </motion.div>
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row justify-center items-center">
        <div className="cloud-hosting-info">
          <motion.div
            {...fadeInEffect}
            className=" cloud-hosting-fact one cloud-hosting-fact-rr">
            <p>Cloud Backup and Disaster Recovery</p>
            <div className="loyalty-dev-num">05</div>
          </motion.div>
          <motion.div
            {...fadeInEffect}
            className="cloud-hosting-fact two cloud-hosting-fact-rr">
            <p>Cloud Billing Managed Service</p>
            <div className="loyalty-dev-num">06</div>
          </motion.div>
          <motion.div
            {...fadeInEffect}
            className="cloud-hosting-fact three cloud-hosting-fact-rr">
            <p>Cloud Based Application Management</p>
            <div className="loyalty-dev-num">07</div>
          </motion.div>
          <motion.div
            {...fadeInEffect}
            className="cloud-hosting-fact four cloud-hosting-fact-rr">
            <p>Cost Optimization</p>
            <div className="loyalty-dev-num">08</div>
          </motion.div>
        </div>

        <motion.img
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1.5, delay: 1, ease: "easeInOut" }}
          exit={{ opacity: 0 }}
          src={ProcessTwo}
          width={300}
          height="auto"
          alt="process-two"
        />
      </div>
    </>
  );
};

export default Process;
