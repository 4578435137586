import React from "react";
import "./index.css";
import FeatureCard from "../cards/TenantCard";
import { motion } from "framer-motion";
import { one, two, three } from "../../assets/number";
import PaymentTopCard from "../cards/PaymentTopCard";

const Solution = () => {
  const solutions = [
    {
      heading: "Provides",
      title: "Payment Gateway Solutions (All in One) for All Business Sizes",
      description: [
        "OBS Payment Solutions introduces all-in-one payment gateway solution designed to meet the needs of businesses of all sizes such as small startup or a large enterprise.",
        " OBS provide secure, and user-friendly payment gateway solution that empowers businesses to manage their payments efficiently and focus on growth.",
      ],
      isLeft: true,
    },
    {
      heading: "Tailored",
      title: "Payment Gateway Solutions (Customized) for Different Industries",
      description: [
        "OBS Payment Solutions provides a comprehensive payment gateway solution designed to cater to the unique requirements of different industries.",
        "OBS empowers businesses to manage their payments efficiently, providing customized services to meet the specific needs of various industries.",
      ],
      isLeft: false,
    },
  ];
  return (
    <>
      <div className="solution-card-container">
        {/* <motion.img
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          viewport={{ once: false, amount: 0.8 }}
          src={SolutionAllinOne}
          alt="solution-all-in-one"
        />
        <motion.img
          initial={{ opacity: 0, x: 50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          exit={{ opacity: 0, x: 200 }}
          viewport={{ once: false, amount: 0.8 }}
          src={SolutionCustomized}
          alt="solution-customized"
        /> */}
        {solutions.map((solution, index) => (
          <PaymentTopCard
            title={solution.title}
            heading={solution.heading}
            key={index}
            description={solution.description}
            isleft={solution.isLeft}
          />
        ))}
      </div>

      <h3 className="payment-header">Multiple Payment Methods and Benefits</h3>
      <p className="payment-desc">
        Supports credit and debit cards, bank transfers, digital wallets, and
        mobile payments.Integrations with popular payment platforms like Mobile
        Wallets ( KBZ pay, AYA pay, Wave Pay ) , Mobile Banking ( MPU, Visa ,
        Master card, etc .,)
      </p>
      <div className="payment-cards">
        <motion.div
          initial={{ opacity: 1, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          exit={{ opacity: 0, x: -200 }}
          viewport={{ once: false, amount: 0.5 }}
          className="overflow-x-hidden">
          {" "}
          <FeatureCard
            number={one}
            title="Scalability"
            info="OBS designed to grow with your business, handling increased transaction volumes without compromising on performance."
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 1, x: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          exit={{ opacity: 0, x: 300 }}
          viewport={{ once: false, amount: 0.5 }}
          className="overflow-x-hidden">
          {" "}
          <FeatureCard
            number={two}
            title="Cost-Effective"
            info="Competitive transaction fees and no hidden costs ensure you get the best value for your investment."
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          exit={{ opacity: 0, x: 100 }}
          viewport={{ once: false, amount: 0.5 }}
          className="overflow-x-hidden pr-2">
          <FeatureCard
            number={three}
            title="Customer Trust"
            info="Enhanced security measures build customer confidence, leading to higher conversion rates and customer retention."
          />
        </motion.div>{" "}
      </div>
    </>
  );
};

export default Solution;
